<template>
  <div>
    <v-layout align-center justify-end class="pb-3">
      <AddAPI v-on:complete="apiAdded" v-if="checkScope(scopeLiterals.ApiCreate)" />
    </v-layout>

    <v-data-table
      :headers="headers"
      :items="authTokens"
      :pagination.sync="pagination"
      :loading="isLoading > 0"
      select-all
      item-key="name"
      class="tw-border tw-border-neutral-100 tw-rounded-lg tw-overflow-hidden"
      hide-actions
    >
      <v-progress-linear slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable text-xs-' + header.align,
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
            ]"
            @click="changeSort(header.value)"
          >
            <v-icon small>arrow_upward</v-icon>
            {{ header.text }}
          </th>
          <th></th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr :active="props.selected" @click="props.selected = !props.selected">
          <td>{{ props.item.clientId }}</td>
          <td>{{ props.item.description }}</td>
          <td>
            <ul>
              <li v-for="(i, index) in props.item.permissions" v-bind:key="'p-' + props.item.clientId + '-' + index">
                {{ getFriendlyPermission(i) }}
              </li>
            </ul>
          </td>
          <td class="text-xs-right">
            <v-tooltip bottom open-delay="1000">
              <v-btn
                icon
                slot="activator"
                @click="deleteToken(props.item.clientId)"
                v-if="checkScope(scopeLiterals.ApiDelete)"
              >
                <v-icon>fa-close</v-icon>
              </v-btn>
              <span>delete token</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import AddAPI from './AddAPI';

export default {
  props: [],
  data() {
    return {
      authTokens: [],
      pagination: {
        sortBy: 'clientId',
        descending: true,
      },
      headers: [
        {
          text: 'ClientID',
          align: 'left',
          value: 'clientId',
        },
        {
          text: 'Description',
          align: 'left',
          value: 'description',
        },
        {
          text: 'Permissions',
          align: 'left',
          value: 'permissions',
        },
      ],
      isLoading: 0,
    };
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    apiAdded() {
      this.$apollo.queries.authTokens.refetch();
    },
    getFriendlyPermission(perm) {
      if (perm === 'ReadAll') {
        return 'Read All';
      } else if (perm === 'WriteAll') {
        return 'Write All';
      } else {
        return 'Unknown Permission';
      }
    },
    async deleteToken(clientId) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        clientId,
      };

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $clientId: ID!) {
            deleteAuthToken(orgId: $orgId, clientId: $clientId)
          }
        `,
        // Parameters
        variables: vars,
      });

      if (resp.data && resp.data.deleteAuthToken === true) {
        this.showSuccessSnackbar('Token deleted');
      } else {
        let errors = 'Unknown error';
        if (resp.errors) {
          errors = resp.errors.join('<br />');
        }

        this.showErrorSnackbar('Problem deleting token: ' + errors);
      }

      this.$apollo.queries.authTokens.refetch();
    },
  },
  mounted() {
    this.$apollo.queries.authTokens.refetch();
  },
  apollo: {
    authTokens: {
      query: gql`
        query GetAuthTokens($orgId: ID!) {
          authTokens(orgId: $orgId) {
            clientId
            description
            permissions
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    AddAPI,
  },
};
</script>
